import ImproveYourResultsTemplate from '@/modules/questionnaire/components/steps/photo-analysis/photo-analysis-results/improve-your-results/ImproveYourResultsTemplate';

export default {
  title: 'PhotoAnalysisSteps/PhotoAnalysisResults/ImproveYourResults/ImproveYourResultsTemplate'
};

const createStory = componentProps => () => ({
  components: { ImproveYourResultsTemplate },
  componentProps,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px'
  },
  template: `<div :style='$options.wrapStyles'>
    <improve-your-results-template v-bind='$options.componentProps' />
  </div>`
});

export const Base = createStory({});
